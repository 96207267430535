.router-link {
    display: block;
    margin: 10px 0px;
}

.active {
    color: white;
}

.active:hover {
    color: white !important;
}
.menu {
    position: relative;    
    display: block;
    /*background-color: blue;    */
    font-size: 150%;        
    left: 107px; 
    top: 67px;
    width: 250px;
}

.link {
    color: #aaa;
    text-decoration: none;
    cursor: default;
    transition: color 150ms linear;
}

.active {
    cursor: pointer;
}

.active * {
    cursor: pointer;
}

.active:hover {
    color: black;
}

.file-link {
    line-height: 85%;
    cursor: pointer;
}

.footer {
    display: block;
    text-align: center;
    font-size: 80%;
    color: black;
    margin: 20px;
    margin-top: 0;
    opacity: 0.8;
}

.footer:hover {
    color: black;
}

.contact-map {
    position: absolute;
    display: block;
    width: 99px;
    height: 99px;
    background-image: url(/static/media/map_icon.fdef2308.png);
    left: 400px;
}

.contact-table td {
    padding-right: 20px;
}

.cell-top-padding td {
    padding-top: 20px;
}

.small-photo {
	width: 100px;
	height: 70px;
	margin: 3px;
	display: block;
	float: left;
	border-radius: 2px;
}

body {
    margin: 0px;
    font-family: "Raleway", sans-serif;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: white;
}

a {
    color: #BBB;
    text-decoration: none;
}

a:hover {
    color: white;
}

.center-page {
    background-image: url(/static/media/background.41b6b6e4.jpg);
    text-align: left;
    display: inline-block;
    margin: auto;
    width: 1200px;
    height: 768px;
}

.content-div {
    position: relative;
    width: 600px;
    left: 150px;
    text-align: justify;
    display: block;
    margin: auto;
    top: -170px;
    font-size: 26px;
}

.bottom-shadow-div {
    position: relative;
    top: 332px;
    left: 22px;
    width: 1156px;
    height: 360px;
    vertical-align: central;
}

