body {
    margin: 0px;
    font-family: "Raleway", sans-serif;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: white;
}

a {
    color: #BBB;
    text-decoration: none;
}

a:hover {
    color: white;
}

.center-page {
    background-image: url("./assets/background.jpg");
    text-align: left;
    display: inline-block;
    margin: auto;
    width: 1200px;
    height: 768px;
}

.content-div {
    position: relative;
    width: 600px;
    left: 150px;
    text-align: justify;
    display: block;
    margin: auto;
    top: -170px;
    font-size: 26px;
}

.bottom-shadow-div {
    position: relative;
    top: 332px;
    left: 22px;
    width: 1156px;
    height: 360px;
    vertical-align: central;
}
