.footer {
    display: block;
    text-align: center;
    font-size: 80%;
    color: black;
    margin: 20px;
    margin-top: 0;
    opacity: 0.8;
}

.footer:hover {
    color: black;
}
