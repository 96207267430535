.contact-map {
    position: absolute;
    display: block;
    width: 99px;
    height: 99px;
    background-image: url("./assets/map_icon.png");
    left: 400px;
}

.contact-table td {
    padding-right: 20px;
}

.cell-top-padding td {
    padding-top: 20px;
}
