.small-photo {
	width: 100px;
	height: 70px;
	margin: 3px;
	display: block;
	float: left;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}
