.link {
    color: #aaa;
    text-decoration: none;
    cursor: default;
  
    -webkit-transition: color 150ms linear;
    -moz-transition: color 150ms linear;
    -o-transition: color 150ms linear;
    -ms-transition: color 150ms linear;
    transition: color 150ms linear;
}

.active {
    cursor: pointer;
}

.active * {
    cursor: pointer;
}

.active:hover {
    color: black;
}
