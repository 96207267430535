.router-link {
    display: block;
    margin: 10px 0px;
}

.active {
    color: white;
}

.active:hover {
    color: white !important;
}